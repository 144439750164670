import type { StrapiMedia } from "./strapi/Strapi";

export enum VerbatimGroupType {
  CANDIDATE = "candidate",
  ETAB = "etab",
  RESTAURATION_COLLECTIVE = "restauration-collective",
  HOTELLERIE = "hotellerie",
  CAFE_RESTAURANT = "cafe-restaurant",
  COMMERCE_DE_BOUCHE = "commerce-de-bouche",
  TRAITEUR_EVENEMENTIEL = "traiteur-evenementiel",
  LOGISTIQUE_VENTE = "logistique-vente",
  HIDF_LYON = "hidf-lyon",
  INTERIM = "interim",
}

export type VerbatimGroup = {
  id: number;
  title: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
};

export type Verbatim = {
  id: number;
  author: string;
  quote: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  logo_alt: string | null;
  photo_alt: string | null;
  locale: string;
  photo: StrapiMedia | null;
  logo: StrapiMedia | null;
  verbatim_group: VerbatimGroup;
};

export type Verbatims = Verbatim[];

export type Quote = {
  id: string | number;
  blockquote: string;
  author: string;
};
