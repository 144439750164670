<script setup lang="ts">
import type { Quote } from "~/models/Verbatim";

const props = defineProps<{
  quotes: Quote[];
  type?: string;
}>();
</script>

<template>
  <div class="verbatim-boxed verbatim-boxed-wrapper col-md-9" :class="type">
    <div class="verbatim-boxed-quote-container">
      <ul class="verbatim-boxed-quote">
        <li
          v-for="quote in props.quotes"
          :key="quote.blockquote"
          class="verbatim-boxed-quote-item"
        >
          <blockquote>
            {{ quote.blockquote }}
          </blockquote>
          <div class="verbatim-boxed-quote-item-bottom">
            <span class="verbatim-boxed-quote-item-author">{{ quote.author }}</span>
            <span class="verbatim-boxed-quote-item-rate"
              ><NuxtImg src="/img/rate-filled.png" alt="avis google 5/5" />
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
