<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import appConfig from "~/app.config";

const { isDesktop } = useDevice();
</script>

<template>
  <div class="row company-rating">
    <div class="company-rating-wrapper col-xs-12 col-md-3">
      <div class="company-rating-container">
        <div class="company-rating-stars">
          <span class="company-rating-stars--left">{{
            appConfig.companyRating.rate
          }}</span>
          <div
            class="company-rating-stars--right"
            :data-rating="appConfig.companyRating.rate"
          >
            <div class="rating__empty"></div>
            <div class="rating__filled"></div>
          </div>
        </div>
        <div class="company-rating-brand">
          <img
            :src="appConfig.companyRating.logo.src"
            :alt="appConfig.companyRating.logo.alt"
          />
        </div>
      </div>
    </div>
    <VerbatimBoxed v-if="isDesktop" :quotes="appConfig.companyRating.quotes" />
  </div>
</template>
